import React, { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Helmet, HelmetProvider } from "react-helmet-async"

import './App.css'
import Header from './Layouts/Header'
import Footer from './Layouts/Footer'

import Home from './Pages/Home'
import AboutUs from './Pages/AboutUs'
import Projects from './Pages/Projects'
import ProjectsArticle from './Pages/ProjectsArticle'
import CompletedProjects from './Pages/CompletedProjects'
import Blog from './Pages/Blog'
import BlogArticle from './Pages/BlogArticle'
import ContactUs from './Pages/ContactUs'
import TermsAndConditions from './Pages/TermsAndConditions'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import DataUsage from './Pages/DataUsage'
import CookiesPolicy from './Pages/CookiesPolicy'
import NotFound from './Pages/NotFound'
import Promotions from './Pages/Promotions'
import SellYourLand from './Pages/SellYourLand'
import ScrollTop from './Components/ScrollTop'
import BlogCategories from './Pages/BlogCategories'
import MessageSend from './Pages/MessageSend'
import API from './Services/Api'
import Seo from './Components/Seo'
import Loading from './Components/Loading'

function App() {

  const [isLoading, setLoading] = useState(true)
  const [getSeo, setSeo] = useState([])

  const fetchSEO = () => {
    API.get(`seo/uso-de-datos`)
        .then(response => {
            setSeo(response['data'])
            setLoading(false)
            window.scrollTo({ behavior: 'smooth', top: '0px' })
        })
        .catch(error => {
            console.log(error);
        });
  }

  useEffect(() => {
    fetchSEO()
  }, []);

  return (
    isLoading ? <Loading title="Cargando..." />
    :
    <>
      <HelmetProvider>
        <Helmet>
          <title>{getSeo.seo_title}</title>
        </Helmet>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/proyectos' exact element={<Projects />} />
          <Route path='/proyectos/:slug' exact element={<ProjectsArticle />} />
          <Route path='/proyectos/:slug/mensaje-enviado' exact element={<MessageSend />} />
          <Route path='/vende-tu-terreno/mensaje-enviado' exact element={<MessageSend />} />
          <Route path='/proyectos-entregados' exact element={<CompletedProjects />} />
          <Route path='/blog' exact element={<Blog />} />
          <Route path='/blog/:category' exact element={<BlogCategories />} />
          <Route path='/blog/:category/:article' exact element={<BlogArticle />} />
          <Route path='/nosotros' exact element={<AboutUs />} />
          <Route path='/contacto' exact element={<ContactUs />} />
          <Route path='/mensaje-enviado' exact element={<MessageSend />} />
          <Route path='/promociones' exact element={<Promotions />} />
          <Route path='/vende-tu-terreno' exact element={<SellYourLand />} />
          <Route path='/terminos-y-condiciones' exact element={<TermsAndConditions />} />
          <Route path='/politica-de-privacidad' exact element={<PrivacyPolicy />} />
          <Route path='/uso-de-datos' exact element={<DataUsage />} />
          <Route path='/politica-de-cookies' exact element={<CookiesPolicy />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <ScrollTop />
        <Footer />
      </HelmetProvider>
    </>
  )
}

export default App
