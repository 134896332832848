import React, { useEffect, useState } from 'react';
import FsLightbox from 'fslightbox-react'

const Banner = (props) => {
    const banner = props.data
    const [toggler, setToggler] = useState(false)
    const source = (banner.code !== null && banner.code !== '') ? ['https://www.youtube.com/watch?v=' + banner.code] : [process.env.REACT_APP_ASSETS_URL + 'images/' + banner.image_cover];
    const source_types = (banner.code !== null && banner.code !== '') ? ['youtube'] : ['image'];

    useEffect(() => {
        setToggler(true)
    }, []);

  return (
      <>
       {
           banner.status && 
           <FsLightbox
               toggler={toggler}
               sources={source}
               customAttributes={[
                {
                    onClick: () => (banner.code === null || banner.code === '') ? window.open(banner.url, '_blank') : '',
                    className: 'cursor-pointer'
                }
                ]}
                types={source_types}
           />
       }
      </>
  )
}

export default Banner