import React from 'react'

const InvestInYourFuture = (props) => {
    const item = props.data
  return (
    
    <section className="bg-turquoise-primary relative">
        <div className="container">
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10">
                <div className="relative order-last lg:order-first">
                    <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + item.section_five_image} alt="" className="relative lg:absolute w-72 lg:w-9/12 xl:w-8/12 left-1/2 -translate-x-1/2 -bottom-16 md:-bottom-20 2xl:-bottom-28" />
                </div>
                <div className="flex items-center justify-center lg:justify-start lg:text-left">
                    <div className="w-full max-w-[380px] flex flex-col items-center lg:items-start">
                        <p className="w-full font-poppins font-bold h2 text-purple-primary text-center lg:text-left">
                            { item.section_four_title }
                        </p>
                        <p className="w-full h3 font-bold text-purple-primary text-center lg:text-left mb-7">
                            { item.section_four_subtitle }
                        </p>
                        <div className="prose-all mb-7" dangerouslySetInnerHTML={{__html: item.section_four_description_one}}></div>
                        <a href={ item.section_four_description_url } target="_blank" rel="noreferrer" className="btn">{ item.section_four_description_btn }</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default InvestInYourFuture