import React from 'react'

const SliderInt = (props) => {
  return (
    <section className="bg-purple-primary bg-[length:10rem] 2xl:bg-[length:14rem] bg-[url('./images/patron-fondo.svg')] py-10 md:py-20 2xl:py-28">
        <div className="container">
          <h1 className="h1 font-semibold text-white text-center mx-auto w-full max-w-[85%] lg:max-w-[60%] mb-3 lg:mb-4 xl:mb-5">
            {props.title}
          </h1>
          <p className="text-white text-center mx-auto w-full max-w-[85%] lg:max-w-[55%] mb-5 lg:mb-7">
            {props.subtitle}
          </p>
          <div className="relative bg-purple-three w-10 lg:w-20 h-[2px] lg:h-[3px] m-auto"></div>
        </div>
    </section>
  )
}

export default SliderInt