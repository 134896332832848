import React from 'react'

const Values = (props) => {
    const values = props.data
  return (
    values.length > 0 &&
    <section className="bg-white">
        <div className="container">
            <h2 className="h2 text-purple-primary text-center mb-10 md:mb-14 lg:mb-16 2xl:mb-20">
                Valores que nos hacen grande
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-20">
            {
                values.map((item, key) => {
                    return (
                        <div className="flex flex-col items-center justify-center">
                            <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + item.image_cover} alt="" className="w-28 mb-7" />
                            <h3 className="h3 font-bold text-purple-primary mb-4">
                                {item.title}
                            </h3>
                            <p className="text-center">
                            {item.description}
                            </p>
                        </div>
                    );
                })
            }
            </div>
        </div>
    </section>
  )
}

export default Values