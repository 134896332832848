import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import API from '../Services/Api'

const BlogLastNews = () => {

    const [getLastNews, setLastNews] = useState([])

    const fetchBlogLastNews = () => {
        API.get(`news?sort_name=id&sort=DESC&limit=4`)
            .then(response => {
                setLastNews(response['data'])
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchBlogLastNews()
    }, []);

    return (
        <div className="bg-turquoise-primary rounded-lg p-7 lg:p-10 xl:p-12 hidden lg:block">
            <h3 className="h3 font-bold text-purple-primary mb-5">
                Últimas Noticias
            </h3>
            <div className="flex flex-col space-y-6">
            {
                getLastNews.map((item, key) => {
                    return (
                        <Link to={'/blog/' + item.tags_slug + '/' + item.slug} className="w-full grid grid-cols-12 gap-2 xl:gap-5 group" key={ 'lastNewItem-' + key }>
                            <div className="col-span-4">
                                <div className="w-full pb-10/12 bg-center bg-cover" style={{ backgroundImage: `url("${process.env.REACT_APP_ASSETS_URL + 'images/' + item.image_cover}")` }}></div>
                            </div>
                            <div className="col-span-8 flex flex-row items-center">
                                <h2 className="transition-alls group-hover:text-purple-primary group-hover:font-bold">
                                    {item.title}
                                </h2>
                            </div>
                        </Link>
                    );
                })
            }

            </div>
        </div>
    )
}

export default BlogLastNews