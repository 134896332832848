import React, { useState, useEffect } from 'react'
import Loading from '../Components/Loading'
import ConstructingDreams from '../Components/ConstructingDreams'
import InvestInYourFuture from '../Components/InvestInYourFuture'
import MisionAndVision from '../Components/MisionAndVision'
import NumberOfFamilies from '../Components/NumberOfFamilies'
import SliderInt from '../Components/SliderInt'
import Values from '../Components/Values'
import Seo from '../Components/Seo'
import API from '../Services/Api'
import axios from 'axios'

const AboutUs = () => {
  const [isLoading, setLoading] = useState(true);
  const [getSeo, setSeo] = useState([])
  const [AboutUsData, setAboutUsData] = useState([])
  const [AboutUsValues, setAboutUsValues] = useState([])

  
  const fetchData = () => {

    const requestSeo = API.get(`seo/nosotros`);
    const requestAboutUs = API.get(`about_us`);
    const requestAboutUsValues = API.get(`about_us/values`);

    axios.all([requestSeo, requestAboutUs, requestAboutUsValues])
      .then(
        axios.spread((...responses) => {
          setSeo(responses[0]['data'])
          setAboutUsData(responses[1]['data'])
          setAboutUsValues(responses[2]['data'])
          setLoading(false)
        })
      )
      .catch(errors => {
        console.error(errors);
      });
	};

  useEffect(() => {
    fetchData()
  }, []);
    
  return (
    
    isLoading ? <Loading title="Cargando..." />
    :
    <>
      <Seo
        title={getSeo.seo_title}
        type={'website'}
        description={getSeo.seo_description}
        readTime={2}
        covers={""}
        keywords={getSeo.seo_keywords}
      />

      <SliderInt title={getSeo.title} subtitle={getSeo.description_one} />

      <ConstructingDreams data={AboutUsData} />
      <NumberOfFamilies data={AboutUsData} />
      <MisionAndVision data={AboutUsData} />
      <Values data={AboutUsValues} />
      <InvestInYourFuture data={AboutUsData} />
    </>
  )
}

export default AboutUs