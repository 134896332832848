import React from 'react'

const ConstructingDreams = (props) => {
    const item = props.data
  return (
    <section className="bg-white lg:pb-44">
        <div className="container">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                <div>
                    <div className="w-full flex flex-col sm:flex-row sm:space-x-4 mb-14">
                        <div className="w-full sm:w-1/2 px-5 sm:border-r sm:border-purple-primary flex flex-col justify-center mb-4 sm:mb-0">
                            <p className="h1 font-bold text-purple-primary text-center">
                                {item.section_one_title}
                            </p>
                            <p className="h2 font-bold text-purple-primary text-center">
                                {item.section_one_subtitle}
                            </p>
                        </div>
                        <div className="w-full sm:w-1/2 px-5">
                            <p className="text-center sm:text-left">
                                {item.section_one_description}
                            </p>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 justify-items-center">
                        <div className="w-full max-w-[340px] flex flex-col items-center justify-center bg-lightblue-secondary rounded-xl py-7 px-5 min-h-[60px]">
                            <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + item.section_one_card_one_image} alt="nosotros" className="h-[78px] lg:h-[60px] 2xl:h-auto mb-3" />
                            <p className="h3 font-semibold text-white text-center">
                                {item.section_one_card_one_title}
                            </p>
                        </div>
                        <div className="w-full max-w-[340px] flex flex-col items-center justify-center bg-lightblue-primary rounded-xl py-7 px-5 min-h-[60px]">
                            <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + item.section_one_card_two_image} alt="nosotros" className="h-[78px] lg:h-[60px] 2xl:h-auto mb-3" />
                            <p className="h3 font-semibold text-white text-center">
                                {item.section_one_card_two_title}
                            </p>
                        </div>
                        <div className="w-full max-w-[340px] flex flex-col items-center justify-center bg-turquoise-primary rounded-xl py-7 px-5 min-h-[60px]">
                            <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + item.section_one_card_three_image} alt="nosotros" className="h-[78px] lg:h-[60px] 2xl:h-auto mb-3" />
                            <p className="h3 font-semibold text-white text-center">
                                {item.section_one_card_three_title}
                            </p>
                        </div>
                        <div className="w-full max-w-[340px] flex flex-col items-center justify-center bg-purple-primary rounded-xl py-7 px-5 min-h-[60px]">
                            <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + item.section_one_card_four_image} alt="nosotros" className="h-[78px] lg:h-[60px] 2xl:h-auto mb-3" />
                            <p className="h3 font-semibold text-white text-center">
                                {item.section_one_card_four_title}
                            </p>
                        </div>
                    </div>

                </div>
                <div className="flex justify-center">
                    <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + item.section_one_image} alt="" className="w-full lg:max-w-[400px] 2xl:max-w-[500px] object-cover" />
                </div>
            </div>
        </div>
    </section>
  )
}

export default ConstructingDreams