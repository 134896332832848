import React from 'react'

const Financing = (props) => {
  return (
    props.data.length > 0 && 
      <section className="bg-white pb-0">
          <div className="container">
              <h2 className="h2 text-purple-primary text-center mb-10 md:mb-14 lg:mb-16 2xl:mb-20">
                Financiamiento directo y sin intereses
              </h2>
              <div className="grid grid-cols-1 lg:grid-cols-3 justify-items-center gap-5 lg:gap-10">
                {props.data.map((item, key) => 
                  <div className="flex items-center justify-center rounded-xl py-[70px] w-full max-w-[400px] px-3" style={{ backgroundColor: item.colour }} key={key}>
                    <div className="text-white text-center">
                      <span className="font-bold h3">
                        {item.title}
                      </span>
                      <p className="h1 font-bold">
                        {item.subtitle}
                      </p>
                    </div>
                  </div>
                )}
              </div>
          </div>
      </section>
  )
}

export default Financing