import React from 'react'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
  } from "react-google-maps";

const GoogleMaps = withScriptjs(withGoogleMap(props =>
    
    <GoogleMap
      defaultZoom={13}
      defaultCenter={ props.center }
    >
      <Marker
        position={ props.center }
        icon={{
            url: `${process.env.REACT_APP_ASSETS_URL + 'images/' + props.logo}`,
            size: new window.google.maps.Size(94, 117),
            scaledSize: new window.google.maps.Size(94, 117),
            anchor: new window.google.maps.Point(0, 117)
        }}
      />
    </GoogleMap>
  ));

export default GoogleMaps