import React from 'react'

const CompletedProjectsCard = (props) => {
    const project = props.data;
    return (
        <div>
            <div className="relative w-full pb-[122%] bg-cover bg-center bg-no-repeat mb-5 2xl:mb-6" style={{ backgroundImage: `url("${process.env.REACT_APP_ASSETS_URL + 'images/' + project.image_cover}")` }}></div>
            <div className="relative flex flex-col justify-center">
                <h2 className="h3 font-bold text-purple-primary text-center mb-1">
                    {project.title}
                </h2>
                <p className="text-center mb-1">
                    {project.subtitle}
                </p>
                <p className="text-center mb-0">
                    {project.year}
                </p>
            </div>
        </div>
    )
}

export default CompletedProjectsCard