import React, { useState, useEffect } from 'react'
import Loading from '../Components/Loading'
import BlogMenuCategories from '../Components/BlogMenuCategories'
import BlogLastNews from '../Components/BlogLastNews'
import SliderInt from '../Components/SliderInt'
import BlogCard from '../Components/BlogCard'
import Seo from '../Components/Seo'
import ReactPaginate from 'react-paginate'
import API from '../Services/Api'
import { useLocation } from 'react-router-dom'

const Blog = () => {
  const [isLoading, setLoading] = useState(true)
  const [getSeo, setSeo] = useState([])
  const [getNews, setNews] = useState([])
  const [pageCount, setPageCount] = useState(1)
  
  const search = new URLSearchParams(useLocation().search).get("search")

  const fetchNews = (page = 1, search) => {
    const url = search ? `news?per_page=9&page=${page}&sort_name=id&sort=DESC&search=${search}` : `news?per_page=9&page=${page}&sort_name=id&sort=DESC`;
    API.get(url)
        .then(response => {
            setNews(response['data']['data'])
            setPageCount(response['data']['total'] / 9)
            setLoading(false)
            window.scrollTo({ behavior: 'smooth', top: '0px' })
        })
        .catch(error => {
            console.log(error)
        });
  }

  const fetchSEO = () => {
    API.get(`seo/blog`)
        .then(response => {
            setSeo(response['data'])
        })
        .catch(error => {
            console.log(error);
        })
  }

	const handlePageChange = (selectedObject) => {
		fetchNews(selectedObject.selected + 1)
	}

  useEffect(() => {
      fetchSEO()
      fetchNews(1, search)
  }, [search])

  return (
      isLoading ? (
        <Loading title="Cargando..." />
			) : 
        <>
          <Seo
            title={getSeo.seo_title}
            type={'website'}
            description={getSeo.seo_description}
            readTime={2}
            covers={""}
            keywords={getSeo.seo_keywords}
          />
          <SliderInt title={getSeo.title} subtitle={getSeo.description_one} />

          <section className="bg-white">
              <div className="container">
                  <div className="grid grid-cols-12 gap-y-20 lg:gap-10 xl:gap-20">
                      <div className="col-span-12 lg:col-span-8">
                        <div className={getNews.length > 0 ? 'grid grid-cols-1 sm:grid-cols-2 sm:gap-x-10 gap-y-16' : 'grid grid-cols-1 sm:gap-x-10 gap-y-16' } >
                          {
                            getNews.length > 0 ?
                              getNews.map((item, key) => {
                                return (
                                  <BlogCard data={item} index={key} key={ 'BlogCard-' + key} />
                                );
                              })
                            :
                            <div className="prose">
                              <h3 className="text-purple-primary font-bold">Resultado</h3>
                              <p>
                                No se encontraron artículos relacionados a su búsqueda "{search}".
                              </p>
                            </div>
                          }

                        </div>
                        <ReactPaginate
                          pageCount={pageCount}
                          pageRangeDisplayed={1}
                          previousLabel='«'
                          nextLabel='»'
                          marginPagesDisplayed={3}
                          onPageChange={handlePageChange}
                          containerClassName={'paginate'}
                          disabledClassNae={'disabled'}
                          activeClassName={'active'}
                          renderOnZeroPageCount={null}
                        />

                      </div>

                      <div className="row-start-1 col-span-12 lg:row-auto lg:col-span-4 space-y-10">
                        <BlogMenuCategories />
                        <BlogLastNews />
                      </div>
                  </div>
              </div>
          </section>
        </>
  )
}


export default Blog