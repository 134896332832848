import React, { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react"
import FsLightbox from 'fslightbox-react'
import { FaPlay } from "react-icons/fa"

// import required modules
import { Autoplay, Lazy, FreeMode, Navigation, Thumbs } from "swiper"

// Import Swiper styles
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"
import "swiper/css/lazy"


const Gallery = (props) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [lightboxController, setLightboxController] = useState({
            toggler: false,
            slide: 1
        });
        
    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    const galerry_arr = props.data.map((item) => {
        return item.code !== null && item.code !== "" ? 'https://www.youtube.com/watch?v=' + item.code : process.env.REACT_APP_ASSETS_URL + 'images/' + item.image_cover;
      });

  return (
        props.data.length > 0 && 
        <>
            <section className="bg-white p-5 lg:p-10">
                <Swiper
                    style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                    }}
                    lazy={true}
                    autoHeight={true}
                    spaceBetween={0}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[Autoplay, Lazy, FreeMode, Thumbs]}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    className="mySwiper2"
                >
                    {props.data.map((item, key) => 
                        <SwiperSlide className="cursor-pointer" key={ 'swiper2-' + item.id } onClick={() => openLightboxOnSlide(key + 1)}>
                            <div data-background={process.env.REACT_APP_ASSETS_URL + 'images/' + item.image_cover} className="swiper-lazy relative w-full pb-[38.7%] bg-cover bg-center bg-no-repeat z-0">
                                <div className="swiper-lazy-preloader animate-spin"></div>
                            </div>
                            {
                            item.code !== null && item.code !== '' && (
                                <>
                                    <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40 z-10"></div>
                                    <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center z-20">
                                        <div className="relative bg-purple-primary rounded-full p-6">
                                            <FaPlay className="text-white text-4xl" />
                                        </div>
                                    </div>
                                </>
                            )
                            }
                        </SwiperSlide>
                    )}
                </Swiper>
                <Swiper
                    lazy={true}
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={6}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[Lazy, FreeMode, Navigation, Thumbs]}
                    breakpoints={{
                        0: {
                        slidesPerView: 2,
                        },
                        600: {
                        slidesPerView: 4,
                        },
                        1024: {
                        slidesPerView: 6,
                        },
                    }}
                    className="mySwiperThumb mt-3"
                >
                    {props.data.map((item) => 
                        <SwiperSlide className="cursor-pointer" key={ 'swiperthumb-' + item.id}>
                            <div data-background={process.env.REACT_APP_ASSETS_URL + 'images/' + item.image_cover} className="swiper-lazy relative w-full pb-[38.7%] bg-cover bg-center bg-no-repeat z-0">
                                <div className="swiper-lazy-preloader animate-spin"></div>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
                <FsLightbox
                    toggler={lightboxController.toggler}
                    sources={galerry_arr}
                    slide={lightboxController.slide}
                />
            </section>
        </>
  )
}

export default Gallery