import React, { useState, useEffect } from 'react'
import Loading from '../Components/Loading'
import BlogCategories from '../Components/BlogMenuCategories'
import BlogLastNews from '../Components/BlogLastNews'
import SliderInt from '../Components/SliderInt'
import { FaFacebookF, FaWhatsapp, FaTwitter, FaLinkedinIn } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import Seo from '../Components/Seo'
import API from '../Services/Api'
import Moment from 'moment';

const BlogArticle = () => {
    const { category, article } = useParams()
    const [isLoading, setLoading] = useState(true)
    const [getNew, setNew] = useState(true)

    const fetchBlogArticle = (category, article) => {
        API.get(`news/${category}/${article}`)
            .then(response => {
                setNew(response['data'])
                setLoading(false)
                window.scrollTo({ behavior: 'smooth', top: '0px' })
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchBlogArticle(category, article)
    }, [category, article]);
    
    return (
        isLoading ? <Loading title="Cargando..." />
        :
        <>
            <Seo
                title={getNew.seo_title}
                type={'article'}
                description={getNew.seo_description}
                readTime={2}
                covers={process.env.REACT_APP_ASSETS_URL + 'images/' + getNew.image_cover}
                keywords={getNew.seo_keywords}
            />
            <SliderInt title={getNew.title} subtitle={'Publicado el ' + Moment(getNew.created_at).format('DD/MM/YYYY')} />
            <section className="bg-white">
            <div className="container">
                <div className="grid grid-cols-12 gap-y-20 lg:gap-10 xl:gap-20">

                    <div className="col-span-12 lg:col-span-8">
                        <div className="w-full prose-all max-w-full mb-20" dangerouslySetInnerHTML={{__html: getNew.description}}></div>
                        <div className="w-full flex justify-center">
                            <ul className="flex flex-row space-x-4">
                                <li>
                                    <a href={ 'https://www.facebook.com/sharer.php?u=' + encodeURIComponent(window.location.href)  } rel="noreferrer" target="_blank" className="rounded-full bg-purple-primary text-white w-14 h-14 flex flex-row hover:bg-turquoise-primary">
                                        <FaFacebookF  className="m-auto text-white text-2xl" />
                                    </a>
                                </li>
                                <li>
                                    <a href={ 'https://api.whatsapp.com/send?text=' + encodeURIComponent(getNew.seo_title + ' ' + window.location.href)  } rel="noreferrer" target="_blank" className="rounded-full bg-purple-primary text-white w-14 h-14 flex flex-row hover:bg-turquoise-primary">
                                        <FaWhatsapp  className="m-auto text-white text-6xl" />
                                    </a>
                                </li>
                                <li>
                                    <a href={ 'http://twitter.com/share?url=' + encodeURIComponent(getNew.seo_title) + '&url=' + encodeURIComponent(window.location.href)  } rel="noreferrer" target="_blank" className="rounded-full bg-purple-primary text-white w-14 h-14 flex flex-row hover:bg-turquoise-primary">
                                        <FaTwitter  className="m-auto text-white text-2xl" />
                                    </a>
                                </li>
                                <li>
                                    <a href={ 'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(window.location.href)  } rel="noreferrer" target="_blank" className="rounded-full bg-purple-primary text-white w-14 h-14 flex flex-row hover:bg-turquoise-primary">
                                        <FaLinkedinIn  className="m-auto text-white text-2xl" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                  <div className="col-span-12 lg:col-span-4 space-y-10">
                    <BlogCategories />
                    <BlogLastNews />
                  </div>
                </div>
            </div>
            </section>
        </>
  )
}

export default BlogArticle