import React, {useState, useEffect} from 'react'
import { Link, NavLink } from 'react-router-dom'
import { FaFacebookF } from 'react-icons/fa'
import { HiOutlineChevronUp, HiOutlineChevronDown, HiOutlineX, HiMenu } from "react-icons/hi";
import { slide as Menu } from 'react-burger-menu'
import API from '../Services/Api'

const Header = () => {
  const [isOpen, setOpen] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [getSettings, setSettings] = useState([])
  const [getProjects, setProjects] = useState([])
  const [small, setSmall] = useState(false);

  let toggleMenu = function(state) {
    return setOpen(state.isOpen);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 120)
      )
    }
    fetchData();
  }, []);

  const fetchData = () => {
    API.get(`settings`)
      .then(response => {
        setSettings(response['data'])
      })
      .catch(error => {
        console.log(error);
      });
    API.get('projects')
      .then(response => {
        setProjects(response['data'])
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <>
      <Menu isOpen={isOpen} onStateChange={ toggleMenu } width={ '247px' }>
        
        <div className="relative py-4 px-5">
          <Link to="/" onClick={ () => setOpen(false) } className="block">Inicio</Link>
        </div>
        <div>
          <div className="relative py-4 px-5">
            <Link to="/proyectos" onClick={ () => setOpen(false) } className="block">Proyectos</Link>
            <div className="absolute right-0 top-0 border-l border-purple-secondary px-4 h-full flex items-center" onClick={ () => setMenuOpen(!isMenuOpen) }>
              { isMenuOpen ? (<HiOutlineChevronUp />) : (<HiOutlineChevronDown />) }
            </div>
          </div>
          <ul className={ isMenuOpen ? 'border-t border-purple-secondary bg-purple-secondary' : 'border-t border-purple-secondary bg-purple-secondary hidden' }>
            {
              getProjects.map((item, index) => {
                return (
                  <li key={ item.id }>
                    <Link to={ '/proyectos/' + item.slug} onClick={ () => setOpen(false) } className="py-3 px-7 border-b border-purple-primary block">{ item.title }</Link>
                  </li>
                )
              })
            }
          </ul>
        </div>
        
        <div className="relative py-4 px-5">
          <Link to="/blog" onClick={ () => setOpen(false) } className="block">Blog</Link>
        </div>
        <div className="relative py-4 px-5">
          <Link to="/nosotros" onClick={ () => setOpen(false) } className="block">Nosotros</Link>
        </div>
        <div className="relative py-4 px-5">
          <Link to="/contacto" onClick={ () => setOpen(false) } className="block">Contacto</Link>
        </div>
        <div className="relative py-4 px-5">
          <Link to="/promociones" onClick={ () => setOpen(false) } className="block">Promociones</Link>
        </div>
        <div className="relative py-4 px-5">
          <Link to="/vende-tu-terreno" onClick={ () => setOpen(false) } className="block">Vende tu terreno</Link>
        </div>
        <div className="relative py-4 px-5">
          <Link to="/#form" onClick={ () => setOpen(false) } className="block">Cotizar</Link>
        </div>
      </Menu>
      <div onClick={ () => setOpen(!isOpen) } className="fixed top-4 right-4 flex lg:hidden bg-purple-primary p-2 rounded-lg z-[1001]" rel="noreferrer">
        { isOpen ? (
            <HiOutlineX className="text-4xl text-white" />
          ) 
          : (
            <HiMenu className="text-4xl text-white" />
          )
        }
      </div>
      <header className={`header ${small ? "small" : ""}`}>
        <div className="relative container flex flex-row justify-between items-center lg:items-end">
          <div className="py-4 lg:py-6 2xl:py-7">
            <Link to="/"><img src={process.env.REACT_APP_ASSETS_URL + 'images/' + getSettings.image_logo} alt="" /></Link>
          </div>
          
          <div className="hidden lg:flex absolute top-0 flex-row space-x-2 text-sm 2xl:text-base right-[1rem] sm:right-[2rem] md:right-[3rem]">
            <a href="/promociones" className="bg-turquoise-primary text-purple-secondary font-bold rounded-br-xl rounded-bl-xl py-2.5 px-8 2xl:py-3" rel="noreferrer">PROMOCIONES</a>
            <a href="/vende-tu-terreno" className="bg-lightblue-primary text-white font-bold rounded-br-xl rounded-bl-xl py-2.5 px-8 2xl:py-3" rel="noreferrer">VENDE TU TERRENO</a>
            <Link to="/#form" className="bg-purple-secondary text-white font-bold rounded-br-xl rounded-bl-xl py-2.5 px-8 2xl:py-3" rel="noreferrer">COTIZAR</Link>
          </div>

          <div>
            <ul className="menu">
              <li>
                <NavLink to="/proyectos">Proyectos</NavLink>
              </li>
              <li>
                <NavLink to="/blog">Blog</NavLink>
              </li>
              <li>
                <NavLink to="/nosotros">Nosotros</NavLink>
              </li>
              <li>
                <NavLink to="/contacto">Contacto</NavLink>
              </li>
              {
                getSettings.facebook !== null && getSettings.facebook !== '' && 
                  <li>
                    <a href={getSettings.facebook} target="_blank" rel="noreferrer" className="rounded-full bg-purple-primary text-white w-8 h-8 flex flex-row 2xl:h-9 2xl:w-9">
                      <FaFacebookF className="m-auto text-white text-sm"/>
                    </a>
                  </li>
              }
            </ul>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header