import React, { useState, useEffect } from 'react'
import Loading from '../Components/Loading'
import { Link } from 'react-router-dom'
import SliderInt from '../Components/SliderInt'
import Ico01 from '../images/icon-escribenos.png'
import Ico02 from '../images/icon-llamanos.png'
import Seo from '../Components/Seo'
import API from '../Services/Api'
import axios from 'axios'
import { useForm } from "react-hook-form"


const ContactUs = () => {
    const [isLoading, setLoading] = useState(true)
    const [getSeo, setSeo] = useState([])
    const [getSettings, setSettings] = useState([])
    const [getProjects, setProjects] = useState([])
    
    const { register, handleSubmit, formState: { errors } } = useForm();
    

    const onSubmit = (data) => {
        setLoading(true)
        API.post('contactus', data)
            .then(res=> {
                if(res['data']['status'] === 'success') {
                    window.location = "/mensaje-enviado"
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            })
    }
    
    const fetchData = () => {
        const requestSeo = API.get(`seo/contacto`);
        const requestSettings = API.get(`settings`);
        const requestProjects = API.get(`projects?sort_name=title&sort=ASC`);
        axios.all([requestSeo, requestSettings, requestProjects])
        .then(
            axios.spread((...responses) => {
            console.log(responses);
            setSeo(responses[0]['data'])
            setSettings(responses[1]['data'])
            setProjects(responses[2]['data'])
            setLoading(false)
            window.scrollTo({ behavior: 'smooth', top: '0px' })
            })
        )
        .catch(errors => {
            console.error(errors);
        });
	};

    useEffect(() => {
        fetchData()
    }, []);
    
    return (
        isLoading ? <Loading title="Cargando..." />
    :
    <>
        <Seo
          title={getSeo.seo_title}
          type={'website'}
          description={getSeo.seo_description}
          readTime={2}
          covers={""}
          keywords={getSeo.seo_keywords}
        />
        <SliderInt title={getSeo.title} subtitle={getSeo.description_one} />
        
        <section className="bg-white">
            <div className="container">
                <div className="grid grid-cols-12 gap-y-10 lg:gap-10 xl:gap-16">
                    <div className="col-span-12 lg:col-span-5 flex flex-col items-center justify-center space-y-6">
                        <div className="relative w-full max-w-[500px] flex flex-col justify-center items-center bg-lightblue-primary rounded-xl p-10">
                            <img src={Ico01} alt="" className="w-16 lg:w-20 xl:w-auto m-auto mb-6" />
                            <h2 className="h3 text-white text-center mb-2">
                                Aseguramos tu inversión
                            </h2>
                            <p className="h3 text-white text-center border-b border-white">
                                {getSettings.email}
                            </p>
                            <div className="absolute right-0 top-1/2 -translate-y-1/2 w-5 overflow-hidden">
                                <div className="h-7 bg-white -rotate-45 transform origin-top-right"></div>
                            </div>
                        </div>
                        <div className="relative w-full max-w-[500px] flex flex-col justify-center items-center bg-lightblue-secondary rounded-xl p-10">
                            <img src={Ico02} alt="" className="w-16 lg:w-20 xl:w-auto m-auto mb-6" />
                            <h2 className="h3 text-white text-center mb-2">
                                Llámanos
                            </h2>
                            <p className="h3 text-white text-center border-b border-white">
                                {getSettings.phones}
                            </p>
                            <div className="absolute right-0 top-1/2 -translate-y-1/2 w-5 overflow-hidden">
                                <div className="h-7 bg-white -rotate-45 transform origin-top-right"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 lg:col-span-7 flex lg:justify-end">
                        <div className="m-auto w-full max-w-full md:max-w-[80%] lg:max-w-[90%] xl:max-w-[89%] bg-turquoise-primary rounded-lg p-7 sm:p-12 md:p-14 2xl:p-16">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="flex flex-wrap space-y-5">
                                <div className="w-full">
                                    <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Nombre y apellidos" {...register("full_name", {required: true})} />
                                    {errors?.full_name?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}

                                    <input type="hidden" name="form_title" id="form_title" value="Contacto" {...register("form_title", {required: true})} />
                                    <input type="hidden" name="form_type" id="form_type" value="contactus" {...register("form_type", {required: true})} />
                                    <input type="hidden" name="form_id" id="form_id" value="" {...register("form_id")} />
                                </div>
                                <div className="w-full">
                                    <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Email" {...register("email", {required: true})} />
                                    {errors?.email?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                </div>
                                <div className="w-full">
                                    <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Teléfono" {...register("phone", {required: true})} />
                                    {errors?.phone?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                </div>
                                <div className="w-full">
                                    <select className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" {...register("project", { required: true })}>
                                        <option value="" selected="selected">¿En qué proyecto estás interesado(a)?</option>
                                        {
                                            getProjects.map((item, index) => {
                                                    return (
                                                        <option value={item.title} key={'option-' + index}>{item.title}</option>
                                                    )
                                                }
                                            )
                                        }
                                    </select>
                                    {errors?.project?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                </div>
                                <div className="w-full">
                                    <textarea cols="30" rows="10"  className="w-full py-4 px-6 h-32 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Escribe tu consulta" {...register("message", {required: true})}></textarea>
                                    {errors?.message?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                </div>

                                <div className="w-full">
                                        <label className="flex items-center">
                                            <input type="checkbox" name="terms_and_conditions" className="mr-2 w-4 h-4 border-2 border-purple-primary bg-transparent text-purple-primary focus-visible:outline-none" value="1" {...register("terms_and_conditions", { required: true })} />
                                            <span>
                                                He leído y acepto los <Link to="/terminos-y-condiciones" className="text-purple-primary font-semibold underline">Términos y Condiciones.</Link>
                                            </span>
                                        </label>
                                        {errors?.terms_and_conditions?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                        <label className="flex items-center mt-3">
                                            <input type="checkbox" name="privacy_policy_and_data_use" className="mr-2 w-4 h-4 border-2 border-purple-primary bg-transparent text-purple-primary focus-visible:outline-none" value="1" {...register("privacy_policy_and_data_use", { required: true })} />
                                            <span>
                                                He leído y acepto la <Link to="/politica-de-privacidad" className="text-purple-primary font-semibold underline">Política de Privacidad</Link> y <Link to="/uso-de-datos" className="text-purple-primary font-semibold underline">Uso de Datos</Link>
                                            </span>
                                        </label>
                                        {errors?.privacy_policy_and_data_use?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    </div>

                                <div className="w-full flex justify-center lg:justify-start">
                                    <button className="btn mt-3" type="submit">
                                        Enviar
                                    </button>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default ContactUs