import React from 'react'

const TrustUs = (props) => {
    const AboutUs = props.data
  return (
      <section className="bg-slate-primary">
            <div className="container">
                <h2 className="h2 text-purple-primary text-center mb-3 2xl:mb-4">
                    Confía en nosotros e invierte en el hogar de tu sueños
                </h2>
                <p className="text-center mb-10 md:mb-14 lg:mb-16 2xl:mb-20">
                    Diseñamos proyectos con la visión de mejorar la calidad de vida de las familias peruanas.
                </p>
                <div className="w-full grid grid-cols-12 gap-y-5 2xl:gap-10">
                    <div className="col-span-12 lg:col-span-4 space-y-3 2xl:space-y-5 px-4">
                        <div className="relative min-h-[60px] w-full max-w-[340px] 2xl:max-w-[460px] mx-auto flex justify-center items-center bg-lightblue-secondary rounded-xl py-10 px-5">
                            <div>
                                <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + AboutUs.section_one_card_one_image} alt="" className="h-[78px] 2xl:h-24 m-auto mb-2 2xl:mb-4" />
                                <p className="h3 font-semibold text-white text-center">
                                    {AboutUs.section_one_card_one_title}
                                </p>
                            </div>
                            <div className="absolute right-0 top-1/2 -translate-y-1/2 w-5 overflow-hidden">
                                <div className="h-7 bg-white -rotate-45 transform origin-top-right"></div>
                            </div>
                        </div>
                        <div className="relative min-h-[60px] w-full max-w-[340px] 2xl:max-w-[460px] mx-auto flex justify-center items-center rounded-xl py-10 px-5 bg-turquoise-primary">
                            <div>
                                <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + AboutUs.section_one_card_three_image} alt="" className="h-[78px] 2xl:h-24 m-auto mb-2 2xl:mb-4" />
                                <p className="h3 font-semibold text-white text-center">
                                {AboutUs.section_one_card_three_title}
                                </p>
                            </div>
                            <div className="absolute right-0 top-1/2 -translate-y-1/2 w-5 overflow-hidden">
                                <div className="h-7 bg-white -rotate-45 transform origin-top-right"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 lg:col-span-4 flex items-center justify-center">
                        <div className="py-5 px-12">
                            <p className="font-poppins font-bold text-8xl leading-none sm:text-14xl sm:leading-none md:text-16xl md:leading-none lg:text-32xl lg:leading-none xl:text-34xl xl:leading-none 2xl:text-37xl 2xl:leading-none text-purple-secondary text-center">
                                {AboutUs.section_two_title}
                            </p>
                            <p className="h1 font-bold text-purple-secondary text-center">{AboutUs.section_two_subtitle}</p>
                            <p className="h3 font-bold text-purple-secondary text-center">
                                {AboutUs.section_two_description}
                            </p>
                        </div>
                    </div>
                    <div className="col-span-12 lg:col-span-4 space-y-3 2xl:space-y-5 px-4">

                        <div className="relative min-h-[60px] w-full max-w-[340px] 2xl:max-w-[460px] mx-auto flex justify-center items-center rounded-xl py-10 px-5 bg-lightblue-primary">
                            <div>
                                <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + AboutUs.section_one_card_two_image} alt="" className="h-[78px] 2xl:h-24 m-auto mb-2 2xl:mb-4" />
                                <p className="h3 font-semibold text-white text-center">
                                    {AboutUs.section_one_card_two_title}
                                </p>
                            </div>
                            <div className="absolute left-0 top-1/2 -translate-y-1/2 w-5 overflow-hidden">
                                <div className=" h-7 bg-white rotate-45 transform origin-top-left"></div>
                            </div>
                        </div>
                        <div className="relative min-h-[60px] w-full max-w-[340px] 2xl:max-w-[460px] mx-auto flex justify-center items-center rounded-xl py-10 px-5 bg-purple-primary">
                            <div>
                                <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + AboutUs.section_one_card_four_image} alt="" className="h-[78px] 2xl:h-24 m-auto mb-2 2xl:mb-4" />
                                <p className="h3 font-semibold text-white text-center">
                                    {AboutUs.section_one_card_four_title}
                                </p>
                            </div>
                            <div className="absolute left-0 top-1/2 -translate-y-1/2 w-5 overflow-hidden">
                                <div className=" h-7 bg-white rotate-45 transform origin-top-left"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
      </section>
  )
}

export default TrustUs