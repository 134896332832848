import React, { useState } from 'react'
import IconWhatsapp from '../images/icon-footer-whatsapp.svg'
import IconWhatsappClose from '../images/icon-cerrar-chat.svg'
import IconPerson from '../images/icono-persona-chat.jpg'

const BtnWhatsapp = (props) => {
  const [isOpen, setOpen] = useState(true)
  const item = props.data

  let toggleClick = () => {
    setOpen(!isOpen)
  }

  return (
    <div className="fixed z-40 right-4 bottom-4 md:right-10 md:bottom-10 flex flex-col items-end">
        { isOpen && 
            <div className="relative bg-lightblue-primary rounded-2xl px-5 pb-4 pt-10 mb-4 w-full max-w-[260px]">
                <img src={IconPerson} alt="" className="absolute left-1/2 -translate-x-1/2 -top-10 border-4 border-lightblue-primary rounded-full" />
                <img src={IconWhatsappClose} alt="" className="cursor-pointer absolute w-3 h-3 top-3 right-3" onClick={ () => setOpen(false) } />

                <a href={ item.whatsapp } target="_blank" rel="noreferrer" className="block font-bold text-white text-center leading-relaxed">
                  { item.whatsapp_title }
                </a>
            </div>
        }
        <div className="cursor-pointer bg-lightblue-primary rounded-full flex p-4 md:p-5" onClick={ toggleClick }>
            <img src={IconWhatsapp} alt="" className="w-7 h-7 md:w-9 md:h-9" />
        </div>
    </div>
  )
}

export default BtnWhatsapp