import React, { useState, useEffect } from 'react'
import Loading from '../Components/Loading'
import { Link } from 'react-router-dom'
import SliderInt from '../Components/SliderInt'
import Ico01 from '../images/clip.png'
import Image01 from '../images/imagen-persona-2.png'
import Seo from '../Components/Seo'
import API from '../Services/Api'
import { useForm } from "react-hook-form"

const SellYourLand = () => {
    const [isLoading, setLoading] = useState(true)
    const [getSeo, setSeo] = useState([])
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [ image, setImage] = useState(null)
    const [ flat, setFlat] = useState(null)

    const onSubmit = (data) => {
        setLoading(true)
        const formData = new FormData();
        formData.append("full_name", data.full_name);
        formData.append("form_title", data.form_title);
        formData.append("form_type", data.form_type);
        formData.append("form_id", data.form_id);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("dni", data.dni);
        formData.append("department", data.department);
        formData.append("province", data.province);
        formData.append("district", data.district);
        formData.append("sector", data.sector);
        formData.append("aream2", data.aream2);
        formData.append("areahectareas", data.areahectareas);
        formData.append("price", data.price);
        formData.append("terms_and_conditions", data.terms_and_conditions);
        formData.append("privacy_policy_and_data_use", data.privacy_policy_and_data_use);
        formData.append("image", data.image[0]);
        formData.append("flat", data.flat[0]);

        API.post('contactus', formData)
            .then(res=> {
                console.log(res)
                if(res['data']['status'] === 'success') {
                    window.location = "/vende-tu-terreno/mensaje-enviado"
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            })
            
    }
    

    const fetchSEO = () => {
        API.get(`seo/vende-tu-terreno`)
            .then(response => {
                setSeo(response['data'])
                setLoading(false)
                window.scrollTo({ behavior: 'smooth', top: '0px' })
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchSEO()
    }, []);
  
    return (
        isLoading ? <Loading title="Cargando..." />
    :
    <>
        <Seo
            title={getSeo.seo_title}
            type={'website'}
            description={getSeo.seo_description}
            readTime={2}
            covers={""}
            keywords={getSeo.seo_keywords}
        />
        <SliderInt title={getSeo.title} subtitle={getSeo.description_one} />

        <section className="bg-white relative pb-[200px] md:pb-[300px] lg:pb-[550px]">
            <div className="container relative z-20">
                <div className="grid grid-cols-12 gap-y-10 lg:gap-10 xl:gap-16">
                    <div className="col-span-12 lg:col-span-5 xl:pr-16">
                        <h2 className="h1 text-purple-primary font-bold mb-7 text-center lg:text-left">
                            {getSeo.description_two}
                        </h2>
                        <p className="text-center lg:text-left">
                            {getSeo.description_three}
                        </p>
                    </div>
                    <div className="col-span-12 lg:col-span-7 flex justify-end">
                        <div className="m-auto w-full  bg-turquoise-primary rounded-lg p-7 sm:p-12 md:p-14 2xl:p-16">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h2 className="h2 text-purple-primary font-bold mb-4">Tus datos</h2>
                                <div className="flex flex-wrap space-y-5 mb-10">
                                    <div className="w-full">
                                        <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Nombre y apellidos" {...register("full_name", {required: true})} />
                                        {errors?.full_name?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                        <input type="hidden" name="form_title" id="form_title" value="Vende tu terreno" {...register("form_title", {required: true})} />
                                        <input type="hidden" name="form_type" id="form_type" value="sellyourland" {...register("form_type", {required: true})} />
                                        <input type="hidden" name="form_id" id="form_id" value="" {...register("form_id")} />
                                    </div>
                                    <div className="w-full">
                                        <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Email" {...register("email", {required: true})} />
                                        {errors?.email?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    </div>
                                    <div className="w-full">
                                        <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Teléfono" {...register("phone", {required: true})} />
                                        {errors?.phone?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    </div>
                                    <div className="w-full">
                                        <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="DNI" {...register("dni", {required: true})} />
                                        {errors?.dni?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    </div>
                                </div>
                                <h2 className="h2 text-purple-primary font-bold mb-4">Datos del terreno que deseas vender</h2>
                                <div className="flex flex-wrap space-y-5">
                                    <div className="w-full">
                                        <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Departamento" {...register("department", {required: true})} />
                                        {errors?.department?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    </div>
                                    <div className="w-full">
                                        <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Provincia" {...register("province", {required: true})} />
                                        {errors?.province?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    </div>
                                    <div className="w-full">
                                        <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Distrito" {...register("district", {required: true})} />
                                        {errors?.district?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    </div>
                                    <div className="w-full">
                                        <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Zona / Sector" {...register("sector", {required: true})} />
                                        {errors?.sector?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    </div>
                                    <div className="w-full">
                                        <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Área en m²" {...register("aream2", {required: true})} />
                                        {errors?.aream2?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    </div>
                                    <div className="w-full">
                                        <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Área en hectáreas" {...register("areahectareas", {required: true})} />
                                        {errors?.areahectareas?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    </div>
                                    <div className="w-full">
                                        <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Precio" {...register("price", {required: true})} />
                                        {errors?.price?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    </div>
                                    <div className="w-full">
                                        <label className="relative block w-full py-3 pl-12 pr-6 bg-white border-white border-0 rounded-lg text-[#3d3c59]">
                                            <img src={Ico01} alt="" className="absolute left-5 top-1/2 -translate-y-1/2" />
                                            <span>
                                                Adjuntar imagen (PNG, JPG, JPEG, PDF). Peso máx 1MB
                                            </span>
                                            <input className="hidden top-0 left-0 w-full h-full" type="file" 
                                                {...register("image", {
                                                    onChange: (e) => setImage(e.target.value),
                                                    validate: {
                                                        lessThan10MB: files => files[0]?.size < 1000000 || 'Peso máximo 1MB',
                                                        acceptedFormats: (files) =>
                                                           ["image/jpeg", "image/png", "image/gif", "application/pdf"].includes(
                                                            files[0]?.type
                                                        ) || "Solo PNG, JPG, JPEG o PDF",
                                                    }
                                                })}
                                                name="image"
                                                accept="image/png, image/jpeg, application/pdf" />
                                        </label>
                                        {image && <span className="inline-block mt-2 bg-purple-primary text-white text-xs px-2 py-1 rounded">{image}</span>}
                                        {errors.image && <span className="block mt-1 text-red-700 text-xs">{errors.image.message}</span>}
                                    </div>
                                    <div className="w-full">
                                        <label className="relative block w-full py-3 pl-12 pr-6 bg-white border-white border-0 rounded-lg text-[#3d3c59]">
                                            <img src={Ico01} alt="" className="absolute left-5 top-1/2 -translate-y-1/2" />
                                            <span>
                                                Adjuntar plano (PNG, JPG, JPEG, PDF). Peso máx 1MB
                                            </span>
                                            <input className="hidden top-0 left-0 w-full h-full" type="file" 
                                                {...register("flat", {
                                                    onChange: (e) => setFlat(e.target.value),
                                                    validate: {
                                                        lessThan10MB: files => files[0]?.size < 1000000 || 'Peso máximo 1MB',
                                                        acceptedFormats: (files) =>
                                                        ["image/jpeg", "image/png", "image/gif", "application/pdf"].includes(
                                                            files[0]?.type
                                                        ) || "Solo PNG, JPG, JPEG o PDF",
                                                    }
                                                })}
                                                name="flat"
                                                accept="image/png, image/jpeg, application/pdf" />
                                        </label>
                                        {flat && <span className="inline-block mt-2 bg-purple-primary text-white text-xs px-2 py-1 rounded">{flat}</span>}
                                        {errors.flat && <span className="block mt-1 text-red-700 text-xs">{errors.flat.message}</span>}
                                    </div>

                                    <div className="w-full">
                                        <label className="flex items-center">
                                            <input type="checkbox" name="terms_and_conditions" className="mr-2 w-4 h-4 border-2 border-purple-primary bg-transparent text-purple-primary focus-visible:outline-none" value="1" {...register("terms_and_conditions", { required: true })} />
                                            <span>
                                                He leído y acepto los <Link to="/terminos-y-condiciones" className="text-purple-primary font-semibold underline">Términos y Condiciones.</Link>
                                            </span>
                                        </label>
                                        {errors?.terms_and_conditions?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                        <label className="flex items-center mt-3">
                                            <input type="checkbox" name="privacy_policy_and_data_use" className="mr-2 w-4 h-4 border-2 border-purple-primary bg-transparent text-purple-primary focus-visible:outline-none" value="1" {...register("privacy_policy_and_data_use", { required: true })} />
                                            <span>
                                                He leído y acepto la <Link to="/politica-de-privacidad" className="text-purple-primary font-semibold underline">Política de Privacidad</Link> y <Link to="/uso-de-datos" className="text-purple-primary font-semibold underline">Uso de Datos</Link>
                                            </span>
                                        </label>
                                        {errors?.privacy_policy_and_data_use?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    </div>

                                    <div className="w-full flex justify-center lg:justify-start">
                                        <button className="btn mt-3" type="submit">
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 h-[250px] md:h-[400px] lg:h-[700px] w-full py-0 bg-cover bg-center bg-no-repeat bg-[url('./images/img-fondo-formulario.jpg')] z-10">
                <div className="container">
                    <img src={Image01} alt="" className="absolute bottom-full hidden lg:block w-[400px] xl:w-[500px] 2xl:w-[600px]" />
                </div>
            </div>
        </section>

    </>
  )
}

export default SellYourLand