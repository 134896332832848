import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import IconSearch from '../images/icon-buscador.svg'
import API from '../Services/Api'

const BlogMenuCategories = () => {
    const [getNewsCategories, setNewsCategories] = useState([])
    const searchQuery = new URLSearchParams(useLocation().search).get("search")
    const [search, setSearch] = useState([searchQuery])
    
    const fetchBlogCategories = () => {
        API.get(`news/categories`)
            .then(response => {
            setNewsCategories(response['data'])
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleSearch = (event) => {
        setSearch(event.target.value)
    }
    
    useEffect(() => {
        fetchBlogCategories()
    }, [search]);
    return (
        <div className="bg-turquoise-primary rounded-lg p-7 lg:p-10 xl:p-12">
            <form action="/blog" method="GET">
                <div className="w-full relative mb-5 xl:mb-10">
                    <button type="submit">
                        <img src={IconSearch} alt="" className="absolute w-5 left-5 top-1/2 -translate-y-1/2" />
                    </button>
                    <input type="text" placeholder="Buscar" name="search" value={search} onChange={handleSearch} className="w-full py-3 pl-14 rounded-lg border-white focus:ring-0" />
                </div>
            </form>
            <h3 className="h3 font-bold text-purple-primary mb-3">
                Categorías
            </h3>
            <ul className="MenuBlog">
                {
                    getNewsCategories.map((item, key) => {
                        return (
                            <li key={ 'BlogCategories-' + key}>
                                <Link to={ '/blog/' + item.slug}>{item.title}</Link>
                            </li>);
                    })
                }
            </ul>
        </div>
    )
}

export default BlogMenuCategories