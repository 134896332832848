import React from 'react'
import { Link } from 'react-router-dom'

const ProjectsCard = (props) => {
    const project = props.data
  return (
    <div>
        <div className="relative w-full pt-7/12 pb-7/12 bg-cover bg-no-repeat" style={{ backgroundImage: `url("${process.env.REACT_APP_ASSETS_URL + 'images/' + project.image_cover}")` }}>
            { project.tags_id !== null && 
                (
                    <div className="absolute top-8 2xl:top-10 px-7 py-2.5 rounded-br-lg rounded-tr-lg" style={{ backgroundColor: project.tags_colour }}>
                        <span className="h3 font-semibold text-white">{ project.tags_title }</span>
                    </div>
                )
            }
            <div className="absolute -bottom-[70px] 2xl:-bottom-[90px] left-1/2 -translate-x-1/2 w-32 h-32 bg-slate-primary rounded-full 2xl:w-40 2xl:h-40">
                <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + project.image_logo} alt="" className="max-w-[60%] h-full max-h-[65px] absolute left-1/2 top-6 -translate-x-1/2" />
            </div>
        </div>
        <div className="relative mt-14 flex flex-col justify-center">
            <h2 className="h3 font-bold text-purple-primary text-center mb-2 2xl:mb-3">
                { project.title }
            </h2>
            <p className="text-center mb-5 2xl:mb-7">
                { project.short_description }
            </p>
            <Link to={'/proyectos/' + project.slug} className="btn mx-auto">
                Ver más
            </Link>
        </div>
    </div>
  )
}

export default ProjectsCard