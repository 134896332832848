import React, { useState, useEffect } from 'react'
import Loading from '../Components/Loading'
import { Link } from 'react-router-dom'
import SliderInt from '../Components/SliderInt'
import Seo from '../Components/Seo'
import API from '../Services/Api'

const MessageSend = () => {
    const [isLoading, setLoading] = useState(true)
    const [getSeo, setSeo] = useState([])

    const fetchSEO = () => {
        API.get(`seo/mensaje-enviado`)
            .then(response => {
                setSeo(response['data'])
                setLoading(false)
                window.scrollTo({ behavior: 'smooth', top: '0px' })
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchSEO()
    }, []);
  
    return (
        isLoading ? <Loading title="Cargando..." />
      :
      <>
        <Seo
          title={getSeo.seo_title}
          type={'website'}
          description={getSeo.seo_description}
          readTime={2}
          covers={""}
          keywords={getSeo.seo_keywords}
        />
        
        <SliderInt title={getSeo.title} subtitle={getSeo.description_one} />
    
        <section className="bg-white">
            <div className="container flex flex-col items-center justify-center">
              <p className="text-center mb-10 2xl:mb-12">
                {getSeo.description_two}
              </p>
              <Link to="/" className="btn">Volver</Link>
            </div>
        </section>
      </>
    )
}

export default MessageSend