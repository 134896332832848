import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperAnimation from '@cycjimmy/swiper-animation';
import "animate.css/animate.min.css";

import 'swiper/css'
import "swiper/css/lazy"
import "swiper/css/effect-fade"
import { Lazy, EffectFade, Autoplay } from "swiper"

const Sliders = (props) => {
    const SlidersData = props.data
    let  swiperAnimation = new SwiperAnimation()

    return (
        <>
            <Swiper
                spaceBetween={0}
                effect={"fade"}
                style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                }}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                lazy={true}
                loop={true}
                modules={[Autoplay, Lazy, EffectFade]}
                onSlideChange={(swiper) => swiperAnimation.init(swiper).animate() }
                className="Sliders"
            >
                
                {
                    SlidersData.map((item, key) => {
                        return (
                            <SwiperSlide key={ 'sliders-' + key}>
                                <div data-background={process.env.REACT_APP_ASSETS_URL + 'images/' + item.image_cover} className="swiper-lazy"></div>
                                <div className="absolute w-full h-full bg-black opacity-30 z-20"></div>
                                <div className="w-full h-full flex items-center absolute z-20 overflow-hidden">
                                    <div className="container">
                                        <div className="w-full lg:w-6/12 xl:w-5/12">
                                            <p className="h1 text-white font-Poppins font-semibold mb-6 lg:mb-10 2xl:mb-14 text-center lg:text-left" 
                                            data-swiper-animation="animate__fadeInRight" 
                                            data-duration="0.8s" 
                                            data-swiper-out-animation="animate__fadeOut"
                                            data-out-duration=".2s">
                                                {item.title}
                                            </p>
                                            <div className="relative flex flex-row items-center justify-center lg:justify-start" 
                                            data-swiper-animation="animate__fadeInRight" 
                                            data-duration="0.9s" 
                                            data-swiper-out-animation="animate__fadeOut"
                                            data-out-duration=".2s">
                                                <div className="bg-white overflow-hidden absolute top-0 left-1/2 -translate-x-1/2 lg:top-auto lg:left-0 lg:-translate-x-0 rounded-full w-28 h-28 border-[5px] lg:w-36 lg:h-36 lg:border-[8px] border-turquoise-secondary">
                                                    <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + item.image_logo} alt="" className="w-7/12 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
                                                </div>
                                                <div className="pt-24 lg:pt-0 lg:pl-24 w-full max-w-lg flex flex-col items-center lg:items-start lg:flex-wrap">
                                                    <div className="h3 font-semibold text-white text-center bg-lightblue-primary py-4 pl-8 lg:pl-16 pr-8 rounded-lg rounded-br-lg lg:text-left">
                                                        {item.subtitle}
                                                    </div>
                                                    <div className="h3 font-semibold text-white text-center bg-lightblue-secondary py-3 pl-8 lg:pl-16 pr-8 rounded-br-lg rounded-bl-lg lg:text-left">
                                                        {item.resubtitle}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-lazy-preloader swiper-lazy-preloader-white z-20"></div>
                            </SwiperSlide>
                        );
                    })
                }
                
            </Swiper>
        </>
    )
}

export default Sliders