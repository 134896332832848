import React, { useState, useEffect } from 'react'
import Seo from '../Components/Seo'
import Loading from '../Components/Loading'
import SliderInt from '../Components/SliderInt'
import API from '../Services/Api'

const PrivacyPolicy = () => {
  const [isLoading, setLoading] = useState(true)
  const [getSeo, setSeo] = useState([])

  const fetchSEO = () => {
    API.get(`seo/politica-de-privacidad`)
        .then(response => {
            setSeo(response['data'])
            setLoading(false)
            window.scrollTo({ behavior: 'smooth', top: '0px' })
        })
        .catch(error => {
            console.log(error);
        });
  }

  useEffect(() => {
    fetchSEO()
  }, []);
  
    return (
        isLoading ? <Loading title="Cargando..." />
    :
      <>
        <Seo
          title={getSeo.seo_title}
          type={'website'}
          description={getSeo.seo_description}
          readTime={2}
          covers={""}
          keywords={getSeo.seo_keywords}
        />
        <SliderInt title={getSeo.title} subtitle={getSeo.description_one} />
  
      <section className="bg-white">
          <div className="container">
                <div className="prose-all w-full max-w-full" dangerouslySetInnerHTML={{__html: getSeo.description_two}}></div>
          </div>
      </section>
    </>
  )
}

export default PrivacyPolicy