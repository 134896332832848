import React, { useState, useEffect } from 'react'
import SliderInt from '../Components/SliderInt'
import Gallery from '../Components/Gallery'
import Financing from '../Components/Financing'
import Benefits from '../Components/Benefits'
import PerfectLocation from '../Components/PerfectLocation'
import Invest from '../Components/Invest'
import Loading from '../Components/Loading'
import Seo from '../Components/Seo'
import API from '../Services/Api'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const ProjectsArticle = (props) => {
    let { slug } = useParams();

    const [isLoading, setLoading] = useState(true)
    const [project, setProject] = useState([])
    const [images, setImages] = useState([])
    const [financings, setFinancings] = useState([])
    const [benefits, setBenefits] = useState([])

    const fetchDataProject = (id) => {
        const requestImages = API.get(`projects/${id}/images?sort_name=position&sort=ASC`);
        const requestFinancings = API.get(`projects/${id}/financings?sort_name=position&sort=ASC`);
        const requestBenefits = API.get(`projects/${id}/benefits?sort_name=position&sort=ASC`);

        axios.all([requestImages, requestFinancings, requestBenefits])
        .then(
            axios.spread((...responses) => {
            setImages(responses[0]['data'])
            setFinancings(responses[1]['data'])
            setBenefits(responses[2]['data'])
            setLoading(false)
            })
        )
        .catch(errors => {
            console.error(errors);
        });
	};

    const fetchProject = (slug) => {
        API.get(`projects/${slug}`)
            .then(response => {
                setProject(response['data'])
                fetchDataProject(response['data']['id'])
            })
            .catch(error => {
                console.log(error)
            });
    }

  
    useEffect(() => {
        fetchProject(slug)
    }, [slug])
    
  return (
    isLoading ? 
        <Loading title="Cargando..." />
    :
      <>
        <Seo
            title={project.seo_title}
            type={'article'}
            description={project.seo_description}
            readTime={2}
            covers={process.env.REACT_APP_ASSETS_URL + 'images/' + project.image_cover}
            keywords={project.seo_keywords}
        />
        <SliderInt title={project.title} subtitle={project.description} />
        <Gallery data={images} />
        <Financing data={financings} />
        <Benefits data={benefits} project={project} />
        <PerfectLocation data={project} />
        <Invest data={project} from='project' />
      </>
  )
}

export default ProjectsArticle