import React from 'react'

const PromotionsCard = (props) => {
    const item = props.data
  return (
    <div>
        <div className="relative w-full pt-6/12 pb-6/12 bg-cover bg-center bg-no-repeat mb-8" style={{ backgroundImage: `url("${process.env.REACT_APP_ASSETS_URL + 'images/' + item.image_cover}")` }}></div>
        <div className="relative flex flex-col justify-center">
            <h2 className="h3 font-bold text-purple-primary text-center mb-6">
                { item.title }
            </h2>
            <a href={ item.url } rel="noreferrer" target="_blank" className="btn mx-auto">
                Solicitar oferta
            </a>
        </div>
    </div>
  )
}

export default PromotionsCard