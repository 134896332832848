import React from 'react'

const NumberOfFamilies = (props) => {
    const item = props.data
  return (
    <section className="bg-turquoise-primary relative">
        <div className="container pb-52 lg:pb-0">
            <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + item.section_two_image} alt="" className="absolute w-[320px] lg:w-[540px] xl:w-[600px] 2xl:w-[740px] left-1/2 -translate-x-1/2 lg:left-auto lg:translate-x-0 bottom-0" />
            <div className="grid grid-cols-2 lg:gap-10">
                <div className="col-start-1 col-span-2 lg:col-start-2 flex items-center justify-center">
                    <div className="w-full max-w-xs text-center">
                        <p className="font-poppins font-bold  text-purple-primary text-center text-6xl leading-none sm:text-14xl sm:leading-none md:text-14xl md:leading-none lg:text-18xl lg:leading-none xl:text-22xl xl:leading-none 2xl:text-32xl 2xl:leading-none">
                            {item.section_two_title}
                        </p>
                        <p className="h1 font-bold text-purple-primary">
                            {item.section_two_subtitle}
                        </p>
                        <p className="h2 text-purple-primary px-14 2xl:px-10">
                           {item.section_two_description}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default NumberOfFamilies