import { useEffect } from "react"
import { useLocation } from 'react-router-dom'

export default function ScrollTop() {
  const { pathname, hash, key } = useLocation()

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo({ behavior: 'smooth', top: '0px' })
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        console.log(element)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 1500);
    }
  }, [pathname, hash, key]);

  return null;
}