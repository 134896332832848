import React from 'react'

const MisionAndVision = (props) => {
    const item = props.data
    return (
        <section className="bg-purple-primary bg-[url('./images/land-bg.png')]">
            <div className="container">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                    <div className="flex flex-col items-center justify-center lg:px-10 xl:px-16">
                        <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + item.section_three_image_one} alt="" className="mb-4" />
                        <h3 className="h3 font-bold text-white text-center mb-5">
                            Misión
                        </h3>
                        <p className="text-white text-center">
                            {item.mision}
                        </p>
                    </div>
                    <div className="flex flex-col items-center justify-center lg:px-10 xl:px-16">
                        <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + item.section_three_image_two} alt="" className="mb-4" />
                        <h3 className="h3 font-bold text-white text-center mb-5">
                            Visión
                        </h3>
                        <p className="text-white text-center">
                            {item.vision}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MisionAndVision