import React, { useEffect, useState } from 'react'
import Loading from '../Components/Loading'
import Seo from '../Components/Seo'
import BlogMenuCategories from '../Components/BlogMenuCategories'
import BlogLastNews from '../Components/BlogLastNews'
import SliderInt from '../Components/SliderInt'
import BlogCard from '../Components/BlogCard'
import ReactPaginate from 'react-paginate'
import API from '../Services/Api'
import { useParams } from 'react-router-dom'

const BlogCategories = () => {
    let { category } = useParams();

    const [getCategory, setCategory] = useState([])
    const [getNews, setNews] = useState([])
    const [pageCount, setPageCount] = useState(1)
    const [isLoading, setLoading] = useState(true)
    
    const handleFetch = (page = 1, category = 'all') => {
        API.get(`news?per_page=9&category=${category}&page=${page}&sort_name=id&sort=DESC`)
            .then(response => {
                setNews(response['data']['data'])
                setPageCount(response['data']['total'] / 9)
                setLoading(false)
                window.scrollTo({ behavior: 'smooth', top: '0px' })
            })
            .catch(error => {
                console.log(error);
            });
        }
    
    const fetchCategory = (category) => {
        API.get(`news/categories/${category}`)
            .then(response => {
                setCategory(response['data'])
            })
            .catch(error => {
                console.log(error);
            });
    }

	const handlePageChange = (selectedObject) => {
        handleFetch((selectedObject.selected + 1), category)
    };
    
    useEffect(() => {
        fetchCategory(category)
        handleFetch(1, category)
    }, [category]);

    return (
        isLoading ? (
            <Loading title="Cargando..." />
        ) : 
        <>
        <Seo
          title={getCategory.seo_title}
          type={'website'}
          description={getCategory.seo_description}
          readTime={2}
          covers={""}
          keywords={getCategory.seo_keywords}
        />
        { /*<Seo title={getCategory.seo_title} description={getCategory.seo_dfescription} keywords={getCategory.seo_keywords} img="" />*/}
        <SliderInt title={getCategory.title} subtitle={getCategory.subtitle} />

        <section className="bg-white">
            <div className="container">
                <div className="grid grid-cols-12 gap-y-20 lg:gap-10 xl:gap-20">
                    <div className="col-span-12 lg:col-span-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-10 gap-y-16">
                        {
                            getNews.length > 0 ?
                                getNews.map((item, key) => {
                                    return (
                                        <BlogCard data={item} index={key} key={ 'BlogCard-' + key} />
                                    );
                                })
                            :
                            <div className="prose">
                                <h3 className="text-purple-primary font-bold">Resultado</h3>
                                <p>
                                    No se encontraron artículos.
                                </p>
                            </div>
                        }
                    </div>
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={1}
                        previousLabel='«'
                        nextLabel='»'
                        marginPagesDisplayed={3}
                        onPageChange={handlePageChange}
                        containerClassName={'paginate'}
                        disabledClassNae={'disabled'}
                        activeClassName={'active'}
                        renderOnZeroPageCount={null}
                    />

                    </div>

                    <div className="row-start-1 col-span-12 lg:row-auto lg:col-span-4 space-y-10">
                    <BlogMenuCategories />
                    <BlogLastNews />
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default BlogCategories