import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet-async"
import Helpers from './../helpers'
import API from '../Services/Api'

const Seo = (props) => {

  const [getSettings, setSettings] = useState([])

  const fetchSettings = () => {
    API.get(`settings`)
      .then(response => {
        setSettings(response['data'])
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchSettings();
  }, []);

  const {
    title,
    type,
    description,
    datePublished,
    authorTwitterUserName,
    dateModified,
    covers,
    readTime,
    author,
    keywords
  } = props;

  const domain = window.location.origin;
  const twitter = getSettings.twitter;
  const address = getSettings.address;
  const phone = getSettings.phones;
  const legalName = getSettings.company;
  const logo = process.env.REACT_APP_ASSETS_URL + 'images/' + getSettings.image_logo;
  const imageDefault = process.env.REACT_APP_ASSETS_URL + 'images/' + getSettings.image_seo_default;
  const url = window.location.href;

  const titleToShow = title;
  const authorToShow = author ? author : getSettings.company;
  const authorTwitterUserNameToShow = authorTwitterUserName ? authorTwitterUserName : '';
  const descriptionToShow = description;

  const structuredDataArticle = `{
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://google.com/article"
      },
      "headline": "${titleToShow}",
      "image": "${!Helpers.isEmpty(covers) ? covers : [imageDefault]}",
      "datePublished": "${datePublished}",
      "dateModified": "${dateModified}",
      "author": {
        "@type": "Person",
        "name": "${authorToShow}"
      },
      "publisher": {
        "@type": "Organization",
        "name": "${legalName}",
        "logo": {
          "@type": "ImageObject",
          "url": "${logo}"
        }
      }
    }`

  const structuredDataOrganization = `{
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "image": "${!Helpers.isEmpty(covers) ? covers : [imageDefault]}",
      "@id": "${domain}",
      "name": "${legalName}",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "${address}",
        "addressLocality": "Lima",
        "addressRegion": "Lima",
        "postalCode": "02002",
        "addressCountry": "Perú"
      },
      "review": {
        "@type": "Review",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5"
        },
        "author": {
          "@type": "Person",
          "name": "Tom Smith"
        }
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": '',
        "longitude": ''
      },
      "url": "${domain}",
      "telephone": "${phone}",
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday"
          ],
          "opens": "09:00",
          "closes": "17:00"
        }
      ]
    }`

  return (
    <Helmet>
          <meta
            name='robots'
            content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
            data-react-helmet="true" />
          <title>{titleToShow}</title>
          <meta name="description" content={`${descriptionToShow}`} data-react-helmet="true" />
          <meta name="keywords" content={`${keywords}`} />
          <link rel="canonical" href={`${domain}`} />
          <meta property="og:site_name" content={`${titleToShow}`} data-react-helmet="true" />
          <meta property="og:locale" content="es_PE" data-react-helmet="true" />
          {/* Opengraph meta tags for Facebook & LinkedIn */}
          <meta property="og:url" content={`${url}`} data-react-helmet="true" />
          <meta
            property="og:type"
            content={ type }
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={titleToShow}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={`${descriptionToShow}`}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={`${!Helpers.isEmpty(covers) ? covers : imageDefault}`}
            data-react-helmet="true"
          />
    
          {/*These tags work for Twitter & Slack, notice I've included more custom tags like reading time etc...*/}
          <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
          <meta
            property="article:published_time"
            content={`${datePublished}`}
            data-react-helmet="true"
          />
    
          {type === 'article' && <meta property="article:modified_time" content={`${dateModified}`} data-react-helmet="true" />}
          {type === 'article' && <meta name="twitter:label1" content="Written by" />}
          {type === 'article' && <meta name="twitter:data1" content={`${authorToShow}`} data-react-helmet="true" />}
          {type === 'article' && <meta name="twitter:label2" content="Est. reading time" data-react-helmet="true" />}
          {type === 'article' && <meta name="twitter:data2" content={`${readTime} min read`} data-react-helmet="true" />}
          {type === 'article' && <meta name="twitter:label1" value="Reading time" data-react-helmet="true" />}
          {type === 'article' && <meta name="twitter:data1" value={`${readTime} min read`} data-react-helmet="true" />}
          {type === 'article' && <meta name="author" content={`${authorToShow}`} data-react-helmet="true" />}
    
          <meta name="twitter:creator" content={authorTwitterUserNameToShow} data-react-helmet="true" />
          <meta name="twitter:site" content={twitter} data-react-helmet="true" />
          <meta name="twitter:domain" content={domain} data-react-helmet="true" />
          <meta
            name="twitter:title"
            content={titleToShow}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={`${descriptionToShow}`}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={`${!Helpers.isEmpty(covers) ? covers : imageDefault}`}
            data-react-helmet="true"
          />
    
          {/* Structured data */}
          <script type="application/ld+json">
            {type === 'article'
              ? structuredDataArticle
              : structuredDataOrganization}
          </script>
          
        </Helmet>
  )
}

export default Seo