import React from 'react';

const Benefits = (props) => {
  const project = props.project
  return (
    props.data.length > 0 && 
    <section className="bg-white">
        <div className="container">
            <h2 className="h2 text-purple-primary text-center mb-10 md:mb-14 lg:mb-16 2xl:mb-20">
              Beneficios de vivir en { project.title }
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-10">
              {props.data.map((item) => 
                  <div className="flex flex-col items-center justify-center" key={'benefits-' + item.id}>
                      <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + item.image_cover} alt="" className="h-20 2xl:h-24 mb-5" />
                      <p>
                        {item.title}
                      </p>
                  </div>
              )}
            </div>
        </div>
    </section>
  )
}

export default Benefits