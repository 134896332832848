import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import LogoWhite from '../images/logo-elite-blanco.svg'
import LogoMK from '../images/logo-mk.png'
import { BsTelephoneFill } from 'react-icons/bs'
import { FaInstagram, FaTiktok, FaEnvelope, FaFacebookF, FaWhatsapp, FaTwitter, FaLinkedinIn, FaMapMarkerAlt } from 'react-icons/fa'
import BtnWhatsapp from '../Components/BtnWhatsapp'
import Moment from 'react-moment'
import API from '../Services/Api'
import axios from 'axios'

const Footer = () => {
const [getSettings, setSettings] = useState([]);
const [getOffices, setOffices] = useState([]);

useEffect(() => {
  fetchData();
}, []);


const fetchData = () => {

  const requestSettings = API.get(`settings`);
  const requestOffices = API.get(`offices`);

  axios.all([requestSettings, requestOffices])
    .then(
      axios.spread((...responses) => {
        setSettings(responses[0]['data'])
        setOffices(responses[1]['data'])
      })
    )
    .catch(errors => {
      console.error(errors);
    });
};

return (
<>
  <footer>
    <div className="container py-20">
      <div className="grid grid-cols-12 gap-y-10 md:gap-x-10 md:gap-y-10">
        <div className="col-span-12 lg:col-span-4">
          <img src={LogoWhite} alt="" className="w-32 lg:w-42 xl:w-44 mx-auto lg:mx-0" />
        </div>
        <div className="col-span-12 lg:col-span-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div className="flex flex-col space-y-2 items-center lg:items-start">
              {
              getSettings.phones !== null && getSettings.phones !== '' &&
              <p className="text-white flex items-center">
                <BsTelephoneFill className="text-sm text-white inline mr-2" />
                <span>{ getSettings.phones }</span>
              </p>
              }
              {
              getSettings.email !== null && getSettings.email !== '' &&
              <p className="text-white flex items-center">
                <FaEnvelope className="text-sm text-white inline mr-2" />
                <span>{ getSettings.email }</span>
              </p>
              }
              <ul className="flex flex-wrap items-end justify-center lg:justify-start space-x-2 space-y-2 md:space-x-4">
                {
                getSettings.facebook !== null && getSettings.facebook !== '' &&
                <li>
                  <a href={getSettings.facebook} target="_blank" rel="noreferrer"
                    className="rounded-full bg-purple-primary text-white w-12 h-12 2xl:w-14 2xl:h-14 flex flex-row hover:bg-turquoise-primary">
                    <FaFacebookF className="m-auto text-white text-2xl" />
                  </a>
                </li>
                }
                {
                getSettings.whatsapp !== null && getSettings.whatsapp !== '' &&
                <li>
                  <a href={getSettings.whatsapp} target="_blank" rel="noreferrer"
                    className="rounded-full bg-purple-primary text-white w-12 h-12 2xl:w-14 2xl:h-14 flex flex-row hover:bg-turquoise-primary">
                    <FaWhatsapp className="m-auto text-white text-6xl" />
                  </a>
                </li>
                }
                {
                getSettings.twitter !== null && getSettings.twitter !== '' &&
                <li>
                  <a href={getSettings.twitter} target="_blank" rel="noreferrer"
                    className="rounded-full bg-purple-primary text-white w-12 h-12 2xl:w-14 2xl:h-14 flex flex-row hover:bg-turquoise-primary">
                    <FaTwitter className="m-auto text-white text-2xl" />
                  </a>
                </li>
                }
                {
                getSettings.linkedin !== null && getSettings.linkedin !== '' &&
                <li>
                  <a href={getSettings.linkedin} target="_blank" rel="noreferrer"
                    className="rounded-full bg-purple-primary text-white w-12 h-12 2xl:w-14 2xl:h-14 flex flex-row hover:bg-turquoise-primary">
                    <FaLinkedinIn className="m-auto text-white text-2xl" />
                  </a>
                </li>
                }
                {
                getSettings.instagram !== null && getSettings.instagram !== '' &&
                <li>
                  <a href={getSettings.instagram} target="_blank" rel="noreferrer"
                    className="rounded-full bg-purple-primary text-white w-12 h-12 2xl:w-14 2xl:h-14 flex flex-row hover:bg-turquoise-primary">
                    <FaInstagram className="m-auto text-white text-2xl" />
                  </a>
                </li>
                }
                {
                getSettings.tiktok !== null && getSettings.tiktok !== '' &&
                <li>
                  <a href={getSettings.tiktok} target="_blank" rel="noreferrer"
                    className="rounded-full bg-purple-primary text-white w-12 h-12 2xl:w-14 2xl:h-14 flex flex-row hover:bg-turquoise-primary">
                    <FaTiktok className="m-auto text-white text-2xl" />
                  </a>
                </li>
                }
              </ul>
            </div>
            <div>
              <ul className="space-y-2 text-center lg:text-left">
                <li>
                  <Link to="/terminos-y-condiciones" className="text-white hover:underline">Términos y condiciones
                  </Link>
                </li>
                <li>
                  <Link to="/politica-de-privacidad" className="text-white hover:underline">Política de privacidad
                  </Link>
                </li>
                <li>
                  <Link to="/uso-de-datos" className="text-white hover:underline">Uso de datos</Link>
                </li>
                <li>
                  <Link to="/politica-de-cookies" className="text-white hover:underline">Política de cookies</Link>
                </li>
              </ul>
            </div>

            {
                    getOffices.map((item, key) => {
                        return (
                          <div className="flex flex-col space-y-2 items-center lg:items-start" key={ 'footer_offices_' + key }>
                            <h3 className="h3 text-white font-bold text-center lg:text-left">{item.title}</h3>
                            {
                              item.address !== null && item.address !== '' &&
                                <p className="text-white relative pl-5 text-center lg:text-left">
                                  <FaMapMarkerAlt className="relative lg:absolute lg:left-0 lg:top-1.5 inline mr-1 -mt-1 text-sm text-white" />{item.address}
                                </p>
                            }
                            {
                              item.phones !== null && item.phones !== '' &&
                              <p className="text-white relative pl-5 text-center lg:text-left">
                                <BsTelephoneFill className="relative lg:absolute lg:left-0 lg:top-1.5 inline mr-1 -mt-1 text-sm text-white" />{item.phones}
                              </p>
                            }
                          </div>
                    );
                })
            }


          </div>
        </div>
      </div>


    </div>
    <div className="bg-purple-primary">
      <div
        className="container flex flex-col items-center text-center space-y-2 md:text-left md:space-y-0 md:flex-row md:items-start md:justify-between py-4 text-xs text-white">
        <p className="uppercase">©
          <Moment locale="pe" format='YYYY' />. { getSettings.company } TODOS LOS DERECHOS RESERVADOS</p>
        <a href="https://www.agenciamk.com" rel="noreferrer" target="_blank" className="flex flex-row">
          <span>
            MARKETING DIGITAL
          </span>
          <img src={LogoMK} alt="" className="ml-2" />
        </a>
      </div>
    </div>
  </footer>
  {
  getSettings.whatsapp !== null && getSettings.whatsapp !== '' &&
  <BtnWhatsapp data={ getSettings } />
  }
</>
)
}

export default Footer