import React from 'react'
import IconGoogleMap from '../images/icon-btn-maps.png'
import IconWazeMap from '../images/icon-btn-waze.png'
import GoogleMaps from './GoogleMaps';

const PerfectLocation = (props) => {
    const project = props.data
    let googleMapURLKey = 'https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_MAP_KEY + '&v=3.exp&libraries=geometry,drawing,places'
  return (
    <>
        <section className="bg-white relative">
        <div className="absolute left-0 top-0 w-full h-[500px] 2xl:h-[650px] bg-purple-primary z-10 bg-[url('./images/land-bg.png')]"></div>
        <div className="container relative z-20">
            <h2 className="h2 text-white text-center mb-9">
                Ubicación perfecta
            </h2>
            {
                ((project.url_google_maps !== null && project.url_google_maps !== '') || (project.url_waze !== null && project.url_waze !== '')) &&
                    <ul className="flex flex-col space-y-4 sm:flex-row items-center justify-center sm:space-y-0 sm:space-x-8">
                        {
                            project.url_google_maps !== null && project.url_google_maps !== '' && 
                            <li>
                                <a href={project.url_google_maps} target="_blank" rel="noreferrer" className="bg-white rounded-lg py-3 px-7 2xl:py-5 2xl:px-9 inline-flex items-center">
                                    <img src={IconGoogleMap} alt="" className="mr-4" />
                                    <span className="font-bold text-purple-four">Ir con Maps</span>
                                </a>
                            </li>
                        }
                        {
                            project.url_waze !== null && project.url_waze !== '' && 
                            <li>
                                <a href={project.url_waze} target="_blank" rel="noreferrer" className="bg-white rounded-lg py-3 px-7 2xl:py-5 2xl:px-9 inline-flex items-center">
                                    <img src={IconWazeMap} alt="" className="mr-4" />
                                    <span className="font-bold text-purple-four">Ir con Waze</span>
                                </a>
                            </li>
                        }
                    </ul>
            }
            {
                project.lat !== null && project.lat !== '' && project.lon !== null && project.lon !== '' &&
                    <div className="mt-14 w-full lg:w-4/5 m-auto h-[300px] md:h-[450px] 2xl:h-[600px]">
                        <GoogleMaps 
                            googleMapURL={googleMapURLKey}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `100%` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            center={{ lat: Number(project.lat), lng: Number(project.lon) }}
                            logo={project.image_marker}
                        />
                    </div>
            }
        </div>
        </section>
    </>
  )
}

export default PerfectLocation