import React, { useState, useEffect } from 'react'
import SliderInt from '../Components/SliderInt'
import Loading from '../Components/Loading'
import Seo from '../Components/Seo'
import API from '../Services/Api'
import ReactPaginate from 'react-paginate'
import CompletedProjectsCard from '../Components/CompletedProjectsCard'

const CompletedProjects = () => {
    const [getProjects, setProjects] = useState([])
    const [getSeo, setSeo] = useState([])
    const [pageCount, setPageCount] = useState(1)
    const [isLoading, setLoading] = useState(true)

    const handleFetch = (current = 1) => {
        API.get(`projects-completed?per_page=9&page=${current}`)
            .then(response => {
                console.log(response)
                setProjects(response['data']['data'])
                setPageCount(response['data']['total'] / 9)
                setLoading(false)
                window.scrollTo({ behavior: 'smooth', top: '0px' })
            })
            .catch(error => {
                console.log(error);
            });
    }
    
    const fetchSEO = () => {
        API.get(`seo/proyectos-entregados`)
            .then(response => {
                setSeo(response['data'])
            })
            .catch(error => {
                console.log(error);
            });
    }

	const handlePageChange = (selectedObject) => {
		handleFetch(selectedObject.selected + 1)
	};
  
    useEffect(() => {
        fetchSEO()
        handleFetch()
    }, []);
  
  return (
    isLoading ? <Loading title="Cargando..." />
   :
    <>
        <Seo
          title={getSeo.seo_title}
          type={'website'}
          description={getSeo.seo_description}
          readTime={2}
          covers={""}
          keywords={getSeo.seo_keywords}
        />
      <SliderInt title={getSeo.title} subtitle={getSeo.description_one} />
        
      <section className="bg-white px-[1rem] md:px-[3rem] lg:px-[4rem] 2xl:px-[7rem]">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-7 gap-y-10 lg:gap-y-12 2xl:gap-y-20">
                {
                    getProjects.map((item, key) => {
                        return (
                            <CompletedProjectsCard data={item} key={key} />
                        )
                    })
                }
              </div>

              <ReactPaginate
                pageCount={pageCount}
                pageRangeDisplayed={1}
                previousLabel='«'
                nextLabel='»'
                marginPagesDisplayed={3}
                onPageChange={handlePageChange}
                containerClassName={'paginate'}
                disabledClassNae={'disabled'}
                activeClassName={'active'}
                renderOnZeroPageCount={null}
            />

      </section>
    </>
  )
}

export default CompletedProjects