import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Lazy } from "swiper";
import { Link } from 'react-router-dom';

// Import Swiper styles
import "swiper/css";
import "swiper/css/lazy";

const LandCarousel = (props) => {
    const swiperRef = React.useRef(null);
    const Projects = props.data;

  return (
    <section className="relative bg-slate-primary">
        <div className="absolute left-0 top-0 w-full h-96 bg-purple-primary z-10 bg-[length:10rem] 2xl:bg-[length:14rem] bg-[url('./images/patron-fondo.svg')]"></div>
        <div className="relative container z-20">
            <h2 className="h2 text-white text-center mb-3 2xl:mb-4">
                Terrenos en Ica: encuentra tu lote perfecto aquí
            </h2>
            <p className="text-center text-white mb-10 md:mb-14 lg:mb-16 2xl:mb-20">
                Conoce nuestros proyectos inmobiliarios en Ica y hagamos realidad el lugar donde te gustaría vivir.
            </p>
        </div>
        
        <div className="px-12 lg:px-[74px] w-full relative z-20">
            
            <div className="w-full absolute left-0 top-1/2 -translate-y-1/2 h-10 z-20">
                <div onClick={() => swiperRef.current.swiper.slidePrev()} className="swiper-carousel-prev"></div>
                <div onClick={() => swiperRef.current.swiper.slideNext()} className="swiper-carousel-next"></div>
            </div>

            <Swiper
                ref={swiperRef}
                lazy={true}
                modules={[Lazy]}
                slidesPerView={3}
                spaceBetween={25}
                breakpoints={{
                    0: {
                    slidesPerView: 1,
                    },
                    600: {
                    slidesPerView: 2,
                    },
                    1024: {
                    slidesPerView: 3,
                    },
                }}
                className="swiper-carousel z-10"
            >
                {
                    Projects.map((item, key) => {
                        return (
                            <SwiperSlide key={ 'swiper-projects-' + key}>
                                <div data-background={process.env.REACT_APP_ASSETS_URL + 'images/' + item.image_cover} className="swiper-lazy relative w-full pt-7/12 pb-7/12 bg-cover bg-center bg-no-repeat bg-slate-primary z-0">
                                    { item.tags_id !== null &&  
                                        (
                                            <div className="absolute top-8 2xl:top-10 px-7 py-2.5 rounded-br-lg rounded-tr-lg" style={{ backgroundColor: item.tags_colour }}>
                                                <span className="h3 font-semibold text-white">{ item.tags_title }</span>
                                            </div>
                                        )
                                    }
                                    <div className="absolute -bottom-[70px] 2xl:-bottom-[90px] left-1/2 -translate-x-1/2 w-32 h-32 bg-slate-primary rounded-full 2xl:w-40 2xl:h-40">
                                        <img src={process.env.REACT_APP_ASSETS_URL + 'images/' + item.image_logo} alt="" className="max-w-[60%] h-full max-h-[65px] absolute left-1/2 top-6 -translate-x-1/2" />
                                    </div>
                                    <div className="swiper-lazy-preloader animate-spin"></div>
                                </div>
                                <div className="relative mt-14 flex flex-col justify-center z-0">
                                    <h2 className="h3 font-bold text-purple-primary text-center mb-2 2xl:mb-3">
                                        {item.title}
                                    </h2>
                                    <p className="text-center mb-5 2xl:mb-7">
                                        {item.short_description}
                                    </p>
                                    <Link to={'/proyectos/' + item.slug} className="btn mx-auto">
                                        Ver más
                                    </Link>
                                </div>
                            </SwiperSlide>
                        );
                    })
                }

            </Swiper>
        </div>
    </section>
  )
}

export default LandCarousel