import React, { useState } from 'react'
import Loading from '../Components/Loading'
import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form"
import API from '../Services/Api'
import Person01 from '../images/imagen-persona.png'


const Invest = (props) => {
    const project = props.data
    const [isLoading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        setLoading(true)
        API.post('contactus', data)
            .then(res=> {
            if(res['data']['status'] === 'success') {
                window.location = "/proyectos/" + project.slug + "/mensaje-enviado"
            }
            })
            .catch(error => {
                console.error('There was an error!', error);
            })
    }

  return (
    isLoading ? <Loading title="Cargando..." />
    :
    <>
    <section className="bg-white relative pt-5">
        <div className="container">
            <img src={Person01} alt="" className="absolute bottom-0 w-[450px] 2xl:w-[550px] hidden lg:block" />
            <div className="grid grid-cols-12 lg:gap-10">
                <div className="col-span-12 lg:col-span-5">
                    <p className="h1 font-bold text-purple-primary text-center lg:text-left mb-10 md:mb-14 lg:mb-16 2xl:mb-20">
                        ¡Invierte en tu lote hoy!
                    </p>
                </div>
                <div className="col-span-12 lg:col-span-7 flex justify-center lg:justify-end">
                    <div className="w-full bg-turquoise-primary rounded-lg p-7 sm:p-12 md:p-14 2xl:p-16">
                        <h2 className="h2 text-purple-primary text-center mb-4">Quiero conocer más de {project.title}</h2>
                        <p className="text-center mb-7 md:mb-10">Déjanos tus datos y un asesor se pondrá en contacto contigo.</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="flex flex-wrap space-y-5">
                                <div className="w-full">
                                    <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Nombre y apellidos" {...register("full_name", {required: true})} />
                                    {errors?.full_name?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}

                                    <input type="hidden" name="form_title" id="form_title" value={project.title} {...register("form_title", {required: true})} />
                                    <input type="hidden" name="form_type" id="form_type" value="projects" {...register("form_type", {required: true})} />
                                    <input type="hidden" name="form_id" id="form_id" value="" {...register("form_id")} />
                                </div>
                                <div className="w-full">
                                    <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Email" {...register("email", {required: true})} />
                                    {errors?.email?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                </div>
                                <div className="w-full">
                                    <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Teléfono" {...register("phone", {required: true})} />
                                    {errors?.phone?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                </div>
                                <div className="w-full">
                                    <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="¿En qué proyecto estás interesado(a)?" {...register("project", {required: true})} value={project.title} />
                                    {errors?.project?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                </div>
                                <div className="w-full">
                                    <textarea cols="30" rows="10"  className="w-full py-4 px-6 h-32 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Escribe tu consulta" {...register("message", {required: true})}></textarea>
                                    {errors?.message?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                </div>

                                <div className="w-full">
                                    <label className="flex items-center">
                                        <input type="checkbox" name="terms_and_conditions" className="mr-2 w-4 h-4 border-2 border-purple-primary bg-transparent text-purple-primary focus-visible:outline-none" {...register("terms_and_conditions", { required: true })} />
                                        <span>
                                            He leído y acepto los <Link to="/terminos-y-condiciones" className="text-purple-primary font-semibold underline">Términos y Condiciones.</Link>
                                        </span>
                                    </label>
                                    {errors?.terms_and_conditions?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    <label className="flex items-center mt-3">
                                        <input type="checkbox" name="privacy_policy_and_data_use" className="mr-2 w-4 h-4 border-2 border-purple-primary bg-transparent text-purple-primary focus-visible:outline-none" {...register("privacy_policy_and_data_use", { required: true })} />
                                        <span>
                                            He leído y acepto la <Link to="/politica-de-privacidad" className="text-purple-primary font-semibold underline">Política de Privacidad</Link> y <Link to="/uso-de-datos" className="text-purple-primary font-semibold underline">Uso de Datos</Link>
                                        </span>
                                    </label>
                                    {errors?.privacy_policy_and_data_use?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                </div>

                                <div className="w-full flex justify-center lg:justify-start">
                                    <button className="btn mt-3" type="submit">
                                        Enviar
                                    </button>
                                </div>
                                </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Invest