import React from 'react'
import { Link } from 'react-router-dom'

const BlogCard = (props) => {
    const item = props.data
    const index = props.index
  return (
    <>
        {
            index === 0 ?
                <div className="col-span-1 sm:col-span-2 grid grid-cols-12 gap-5 lg:gap-7 2xl:gap-10 group">
                    <div className="col-span-12 md:col-span-8 overflow-hidden">
                    <div className="transition-all ease-in-out duration-1000 w-full h-full pb-8/12 bg-center bg-cover group-hover:scale-125" style={{ backgroundImage: `url("${process.env.REACT_APP_ASSETS_URL + 'images/' + item.image_cover}")` }}></div>
                    </div>
                    <div className="col-span-12 md:col-span-4">
                    <h2 className="h2 text-purple-primary mb-4">
                        {item.title}
                    </h2>
                    <p className="mb-9 2xl:mb-12 line-clamp-4">
                        {item.short_description}
                    </p>
                    <Link to={'/blog/' + item.tags_slug + '/' + item.slug} className="btn">Leer más</Link>
                    </div>
                </div>
            :
                <Link to={'/blog/' + item.tags_slug + '/' + item.slug} className="block group">
                    <div className="mb-6 overflow-hidden relative">
                        <div className="transition-all ease-in-out duration-1000 w-full pb-8/12 bg-center bg-cover group-hover:scale-125" style={{ backgroundImage: `url("${process.env.REACT_APP_ASSETS_URL + 'images/' + item.image_cover}")` }}></div>
                    </div>
                    <div>
                        <h2 className="h3 font-bold text-purple-primary mb-3">
                            {item.title}
                        </h2>
                        <p className="line-clamp-3">
                            {item.short_description}
                        </p>
                    </div>
                </Link>
        }
    </>
  )
}

export default BlogCard