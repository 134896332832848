import React, { useState, useEffect } from 'react'
import Loading from '../Components/Loading'
import FormWeStart from '../Components/FormWeStart'
import LandsCarousel from '../Components/LandsCarousel'
import Sliders from '../Components/Sliders'
import TrustUs from '../Components/TrustUs'
import Banner from '../Components/Banner'
import Seo from '../Components/Seo'
import API from '../Services/Api'
import axios from 'axios'

const Home = () => {
  const [isLoading, setLoading] = useState(true)
  const [SlidersData, setSlidersData] = useState([])
  const [AboutUsData, setAboutUsData] = useState([])
  const [ProjectsData, setProjectsData] = useState([])
  const [getSeo, setSeo] = useState([])
  const [getBanner, setBanner] = useState([])

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {

    const requestSliders = API.get(`sliders`);
    const requestAboutUs = API.get(`about_us`);
    const requestProjects = API.get(`projects?sort=random&outstanding=1`);
    const requestSEO = API.get(`seo/inicio`);
    const requestBanner = API.get(`banner`);

    axios.all([requestSliders, requestAboutUs, requestProjects, requestSEO, requestBanner])
      .then(
        axios.spread((...responses) => {
          setSlidersData(responses[0]['data'])
          setAboutUsData(responses[1]['data'])
          setProjectsData(responses[2]['data'])
          setSeo(responses[3]['data'])
          setBanner(responses[4]['data'])
          setLoading(false)
        })
      )
      .catch(errors => {
        console.error(errors);
      });
	};

  return (
      isLoading ? <Loading title="Cargando..." />
    :
    <>
      <Sliders data={SlidersData} />
      <TrustUs data={AboutUsData} />
      <LandsCarousel data={ProjectsData} />
      <FormWeStart />
      <Banner data={getBanner} />
    </>
  )
}

export default Home