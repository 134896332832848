import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../Components/Loading'
import { useForm } from "react-hook-form"
import API from '../Services/Api'

const FormWeStart = () => {
  const [isLoading, setLoading] = useState(false)
  const [getProjects, setProjects] = useState([])
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
      setLoading(true)
      API.post('contactus', data)
        .then(res=> {
          if(res['data']['status'] === 'success') {
            window.location = "/mensaje-enviado"
          }
        })
        .catch(error => {
            console.error('There was an error!', error);
        })
  }

  const fetchData = () => {
    API.get(`projects?sort_name=title&sort=ASC`)
        .then(response => {
          setProjects(response['data'])
          setLoading(false)
          window.scrollTo({ behavior: 'smooth', top: '0px' })
        })
        .catch(error => {
            console.log(error);
        });
  }

  useEffect(() => {
    fetchData()
  }, []);

  return (
    isLoading ? <Loading title="Cargando..." />
    :
    <>
    <section className="bg-cover bg-center bg-no-repeat bg-[url('./images/bg-form-02.jpg')]" id="form">
        <div className="container">
            <div className="w-full grid grid-cols-12">
              <div className="col-start-1 col-span-12 lg:col-start-7">
                <div className="bg-slate-primary rounded-xl p-7 sm:p-12 md:p-14 2xl:p-16">
                  <h2 className="h2 text-purple-primary text-left">El lugar ideal pensado para ti ¿Comenzamos?</h2>
                  <div className="relative mt-6 2xl:mt-8">
                    <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="flex flex-wrap space-y-5">
                                <div className="w-full">
                                    <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Nombre y apellidos" {...register("full_name", {required: true})} />
                                    {errors?.full_name?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}

                                    <input type="hidden" name="form_title" id="form_title" value="Contacto" {...register("form_title", {required: true})} />
                                    <input type="hidden" name="form_type" id="form_type" value="contactus" {...register("form_type", {required: true})} />
                                    <input type="hidden" name="form_id" id="form_id" value="" {...register("form_id")} />
                                </div>
                                <div className="w-full">
                                    <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Email" {...register("email", {required: true})} />
                                    {errors?.email?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                </div>
                                <div className="w-full">
                                    <input className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Teléfono" {...register("phone", {required: true})} />
                                    {errors?.phone?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                </div>
                                <div className="w-full">
                                    <select className="w-full py-3 px-6 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" {...register("project", { required: true })}>
                                        <option value="" selected="selected">¿En qué proyecto estás interesado(a)?</option>
                                        {
                                            getProjects.map((item, index) => {
                                                    return (
                                                        <option value={item.title} key={'option-' + index}>{item.title}</option>
                                                    )
                                                }
                                            )
                                        }
                                    </select>
                                    {errors?.project?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                </div>
                                <div className="w-full">
                                    <textarea cols="30" rows="10"  className="w-full py-4 px-6 h-32 bg-white border-white border-0 rounded-lg text-[#3d3c59] placeholder-[#3d3c59] focus:ring-0 focus-visible:outline-none" placeholder="Escribe tu consulta" {...register("message", {required: true})}></textarea>
                                    {errors?.message?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                </div>

                                <div className="w-full">
                                        <label className="flex items-center">
                                            <input type="checkbox" name="terms_and_conditions" className="mr-2 w-4 h-4 border-2 border-purple-primary bg-transparent text-purple-primary focus-visible:outline-none" value="1" {...register("terms_and_conditions", { required: true })} />
                                            <span>
                                                He leído y acepto los <Link to="/terminos-y-condiciones" className="text-purple-primary font-semibold underline">Términos y Condiciones.</Link>
                                            </span>
                                        </label>
                                        {errors?.terms_and_conditions?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                        <label className="flex items-center mt-3">
                                            <input type="checkbox" name="privacy_policy_and_data_use" className="mr-2 w-4 h-4 border-2 border-purple-primary bg-transparent text-purple-primary focus-visible:outline-none" value="1" {...register("privacy_policy_and_data_use", { required: true })} />
                                            <span>
                                                He leído y acepto la <Link to="/politica-de-privacidad" className="text-purple-primary font-semibold underline">Política de Privacidad</Link> y <Link to="/uso-de-datos" className="text-purple-primary font-semibold underline">Uso de Datos</Link>
                                            </span>
                                        </label>
                                        {errors?.privacy_policy_and_data_use?.type === "required" && <span className="text-red-700 text-xs">Este campo es obligatorio</span>}
                                    </div>

                                <div className="w-full flex justify-center lg:justify-start">
                                    <button className="btn mt-3" type="submit">
                                        Enviar
                                    </button>
                                </div>
                              </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default FormWeStart